@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-size: 12px;
}

@media (min-width: 900px) {
  html {
    font-size: 14px; 
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 16px; 
  }
}

@media (min-width: 2000px) {
  html {
    font-size: 18px; 
  }
}


html, body {
  margin: 0;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

@keyframes rotate {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(360deg);
  }
}

body:has(.firebase-emulator-warning) {
  padding-top: 21px;
}

body:has(.firebase-emulator-warning) [class*="ContentStyled"] {
  height: calc(100vh - 21px);
}

.firebase-emulator-warning {
  height: 21px!important;
  bottom: unset!important;
  top: 0!important;
  border: 0!important;
  border-bottom: 1px solid #dedede!important;
  background: #ff0000!important;
  color: #fff!important;
  z-index: 1000!important;
  display: none;
}
